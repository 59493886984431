<template>
    <v-container fluid>
		<v-row justify="center" class="ma-0">
			<v-col cols="12" xs="12" md="8" class="pa-0">
				<v-row v-if="affichagePortable && acces_autorise('modification astreintes manœuvres')" class="ma-0" justify="center">
					<v-btn outlined small fab color="primary" @click="onClickAddAsteinteManoeuvre"><v-icon>mdi-calendar-plus</v-icon></v-btn>
				</v-row>
				<v-row align="center" class="mb-4 px-4 mt-4">
					<v-btn fab outlined small @click="mois_precedent" :loading="chargement_mois_precedent">
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-slide-y-transition mode="out-in">
						<div class="flex-grow-1 text-center primary--text text-h5 font-weight-light" :key="titre_mois">{{ titre_mois }}</div>
					</v-slide-y-transition>
					<v-btn fab outlined small @click="mois_suivant" :loading="chargement_mois_suivant">
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-row>
				<v-calendar id="calendrier_astreintes_manoeuvres" ref="calendar" type="month" 
							locale = 'fr'
							v-model="start.date" 
							:weekdays="jours_semaine" 
							@change="updateRange"
							@click:event="showEvent"
							:events="evenements" 
							:event-margin-bottom="3" 
							:event-color="getEventColor"
							:event-more="false"
							:event-height="40"
							>
					<template v-slot:event="props">
						<v-row class="ma-0 pa-1" style="height: 100%" align="center" justify="center">
							<div>{{props.event.name}}</div>
						</v-row>
					</template>
				</v-calendar>
				<v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement"	offset-x max-width="350">
					<v-card	color="grey lighten-4" flat>
						<v-toolbar	:color="selectedEvent.color" dark flat dense>
							<div>{{selectedEvent.nom_detail}}</div>
							<!-- <v-toolbar-title v-html="selectedEvent.nom_detail"></v-toolbar-title> -->
							<v-spacer></v-spacer>
							<v-menu bottom left v-if="acces_autorise('modification astreintes manœuvres')">
								<template v-slot:activator="{ on }">
									<v-btn dark icon v-on="on">
										<v-icon>mdi-dots-vertical</v-icon>
									</v-btn>
								</template>
								<v-list dense>
									<v-list-item @click="onClickSupprElement">
										<v-list-item-title>Supprimer</v-list-item-title>
									</v-list-item>
									<v-list-item @click="onClickUpdateElement">
										<v-list-item-title>Modifier</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-toolbar>
						<v-card-text>
							<span v-html="selectedEvent.description_detail"></span>
						</v-card-text>
						<v-card-actions>
							<div class="flex-grow-1"></div>
							<v-btn text @click="selectedOpen = false">
								Fermer
							</v-btn>
						</v-card-actions>
						<v-expand-transition>
							<div v-show="affichage_update_astr_man">
								<v-divider></v-divider>
								<v-card-text>
									<v-row class="ma-0">
										<v-select :items="liste_equipes" :multiple="formulaire_maj_element.type == 'manoeuvre'" label="Equipe" item-text="nom" item-value="id" hide-details v-model="formulaire_maj_element.id_equipe"></v-select>
									</v-row>
									<v-row class="ma-0 mt-6">
										<v-col cols="6" class="pa-0">
											<v-menu v-model="menu_date_debut" :close-on-content-click="false" transition="scale-transition" offset-y>
												<template v-slot:activator="{ on }">
													<v-text-field outlined dense
														v-model="formulaire_maj_element.date_debut_formatee"
														label="Date début"
														hide-details
														readonly
														prepend-inner-icon="mdi-calendar"
														@blur="formulaire_maj_element.date_debut_formatee = formatDate(formulaire_maj_element.date_debut) "
														v-on="on"></v-text-field>
												</template>
												<v-date-picker v-model="formulaire_maj_element.date_debut" :first-day-of-week="1" no-title @input="menu_date_debut = false"></v-date-picker>
											</v-menu>
										</v-col>
										<v-col cols="6" class="pa-0 pl-3">
											<v-menu ref="menu_heure_debut" v-model="menu_heure_debut" :close-on-content-click="false" :nudge-right="40" :return-value.sync="formulaire_maj_element.heure_debut"  transition="scale-transition" offset-y>
												<template v-slot:activator="{ on }">
													<v-text-field outlined dense
														v-model="formulaire_maj_element.heure_debut"
														label="Heure début"
														prepend-inner-icon="mdi-clock-outline"
														readonly
														hide-details
														v-on="on"
													></v-text-field>
												</template>
												<v-time-picker v-if="menu_heure_debut" format="24hr"
													v-model="formulaire_maj_element.heure_debut"
													@click:minute="$refs.menu_heure_debut.save(formulaire_maj_element.heure_debut)"
												></v-time-picker>
      										</v-menu>
										</v-col>
									</v-row>
									<v-row class="ma-0 mt-3">
										<v-col cols="6" class="pa-0">
											<v-menu v-model="menu_date_fin" :close-on-content-click="false" transition="scale-transition" offset-y>
												<template v-slot:activator="{ on }">
													<v-text-field outlined dense
														v-model="formulaire_maj_element.date_fin_formatee"
														label="Date fin"
														hide-details
														readonly
														prepend-inner-icon="mdi-calendar"
														@blur="formulaire_maj_element.date_fin_formatee = formatDate(formulaire_maj_element.date_fin) "
														v-on="on"></v-text-field>
												</template>
												<v-date-picker v-model="formulaire_maj_element.date_fin" :first-day-of-week="1" no-title @input="menu_date_fin = false"></v-date-picker>
											</v-menu>
										</v-col>
										<v-col cols="6" class="pa-0 pl-3">
											<v-menu ref="menu_heure_fin" v-model="menu_heure_fin" :close-on-content-click="false" :nudge-right="40" :return-value.sync="formulaire_maj_element.heure_fin"  transition="scale-transition" offset-y>
												<template v-slot:activator="{ on }">
													<v-text-field outlined dense
														v-model="formulaire_maj_element.heure_fin"
														label="Heure fin"
														prepend-inner-icon="mdi-clock-outline"
														readonly
														hide-details
														v-on="on"
													></v-text-field>
												</template>
												<v-time-picker v-if="menu_heure_fin" format="24hr"
													v-model="formulaire_maj_element.heure_fin"
													@click:minute="$refs.menu_heure_fin.save(formulaire_maj_element.heure_fin)"
												></v-time-picker>
      										</v-menu>
										</v-col>
									</v-row>
									<v-row class="ma-0 mt-4" v-if="formulaire_maj_element.type == 'manoeuvre'" align="center">
										<div class="pr-2">Comptant pour la manoeuvre du mois de:</div>
										<v-col cols="8" class="pa-0 mt-3">
											<v-menu v-model="menu_mois_annee_update" :close-on-content-click="false" transition="scale-transition" offset-y>
												<template v-slot:activator="{ on }">
													<v-text-field outlined dense
														v-model="formulaire_maj_element.annee_mois_formatee"
														label="Mois / année"
														hide-details
														readonly
														prepend-inner-icon="mdi-calendar"
														@blur="formulaire_maj_element.annee_mois_formatee = formatMoisAnnee(formulaire_maj_element.mois) "
														v-on="on"></v-text-field>
												</template>
												<v-date-picker type="month" v-model="formulaire_maj_element.mois" @input="menu_mois_annee_update = false"></v-date-picker>
											</v-menu>
										</v-col>
									</v-row>
								</v-card-text>
								<v-card-actions>
									<div class="flex-grow-1"></div>
									<v-btn text @click="onClickAnnulUpdate">
										Annuler
									</v-btn>
									<v-btn text @click="onClickValidUpdate" :loading="chargement_update" :disabled="estFormulaireUpdateInvalid">
										OK
									</v-btn>
								</v-card-actions>
							</div>
						</v-expand-transition>
					</v-card>
				</v-menu>
			</v-col>
			<v-col md="2" v-if="!affichagePortable">
				<v-row class="ma-0">
					<div class="flex-grow-1"></div>
					<v-tooltip left v-if="acces_autorise('modification astreintes manœuvres')">
						<template v-slot:activator="{ on }">
							<v-btn outlined fab v-on="on" color="primary" @click="onClickAddAsteinteManoeuvre"><v-icon>mdi-calendar-plus</v-icon></v-btn>
						</template>
						<span>Ajouter</span>
					</v-tooltip>
				</v-row>
				
			</v-col>
		</v-row>

		<!-- dialogue confirmation -->
        <v-dialog v-model="openDialogueConfirmation" max-width="290" persistent>
            <v-card>
				<v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title>{{ dialogue_confirmation.titre }}</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">{{ dialogue_confirmation.texte }}</v-card-text>
				<v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="blue-grey darken-1" text @click="annuleConfirmation">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="validConfirmation" :loading="chargement_confirmation">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

		<!-- dialogue ajout astreinte/manoeuvre -->
        <v-dialog v-model="openDialogueAjoutAstreinteManoeuvre" max-width="400" persistent>
            <v-card>
				<v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title>Ajout</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
				<v-card-text class="mt-3">
					<v-row class="ma-0">
						<v-radio-group v-model="formulaire_ajout_element.type" row hide-details @change="onClickChangeType">
							<v-radio label="Asteinte" value="astreinte"></v-radio>
							<v-radio label="Manoeuvre" value="manoeuvre"></v-radio>
						</v-radio-group>
					</v-row>
					<v-row class="ma-0 mt-2">
						<v-select :items="liste_equipes" :multiple="formulaire_ajout_element.type == 'manoeuvre'" label="Equipe" item-text="nom" item-value="id" hide-details v-model="formulaire_ajout_element.id_equipe" :disabled="!formulaire_ajout_element.type"></v-select>
					</v-row>
					<v-row class="ma-0 mt-4">
						<v-col cols="6" class="pa-0">
							<v-menu v-model="menu_date_debut_ajout" :close-on-content-click="false" transition="scale-transition" offset-y>
								<template v-slot:activator="{ on }">
									<v-text-field outlined dense
										v-model="formulaire_ajout_element.date_debut_formatee"
										label="Date début"
										hide-details
										readonly
										prepend-inner-icon="mdi-calendar"
										@blur="formulaire_ajout_element.date_debut_formatee = formatDate(formulaire_ajout_element.date_debut) "
										v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="formulaire_ajout_element.date_debut" :first-day-of-week="1" no-title @input="menu_date_debut_ajout = false"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="6" class="pa-0 pl-3">
							<v-menu ref="menu_heure_debut_ajout" v-model="menu_heure_debut_ajout" :close-on-content-click="false" :nudge-right="40" :return-value.sync="formulaire_ajout_element.heure_debut"  transition="scale-transition" offset-y>
								<template v-slot:activator="{ on }">
									<v-text-field outlined dense
										v-model="formulaire_ajout_element.heure_debut"
										label="Heure début"
										prepend-inner-icon="mdi-clock-outline"
										readonly
										hide-details
										v-on="on"
										></v-text-field>
								</template>
								<v-time-picker v-if="menu_heure_debut_ajout" format="24hr"
									v-model="formulaire_ajout_element.heure_debut"
									@click:minute="$refs.menu_heure_debut_ajout.save(formulaire_ajout_element.heure_debut)"
								></v-time-picker>
      						</v-menu>
						</v-col>
					</v-row>
					<v-row class="ma-0 mt-3">
						<v-col cols="6" class="pa-0">
							<v-menu v-model="menu_date_fin_ajout" :close-on-content-click="false" transition="scale-transition" offset-y>
								<template v-slot:activator="{ on }">
									<v-text-field outlined dense
										v-model="formulaire_ajout_element.date_fin_formatee"
										label="Date fin"
										hide-details
										readonly
										prepend-inner-icon="mdi-calendar"
										@blur="formulaire_ajout_element.date_fin_formatee = formatDate(formulaire_ajout_element.date_fin) "
										v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="formulaire_ajout_element.date_fin" :first-day-of-week="1" no-title @input="menu_date_fin_ajout = false"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="6" class="pa-0 pl-3">
							<v-menu ref="menu_heure_fin_ajout" v-model="menu_heure_fin_ajout" :close-on-content-click="false" :nudge-right="40" :return-value.sync="formulaire_ajout_element.heure_fin"  transition="scale-transition" offset-y>
								<template v-slot:activator="{ on }">
									<v-text-field  outlined dense
										v-model="formulaire_ajout_element.heure_fin"
										label="Heure fin"
										prepend-inner-icon="mdi-clock-outline"
										readonly
										v-on="on"
									></v-text-field>
								</template>
								<v-time-picker v-if="menu_heure_fin_ajout" format="24hr"
									v-model="formulaire_ajout_element.heure_fin"
									@click:minute="$refs.menu_heure_fin_ajout.save(formulaire_ajout_element.heure_fin)"
								></v-time-picker>
      						</v-menu>
						</v-col>
					</v-row>
					<v-expand-transition>
						<v-row v-show="formulaire_ajout_element.type == 'manoeuvre'" class="ma-0" align="center">
							<div>Comptant pour la manoeuvre du mois de:</div>
							<v-col cols="8" class="pa-0 mt-3">
								<v-menu v-model="menu_mois_annee" :close-on-content-click="false" transition="scale-transition" offset-y>
									<template v-slot:activator="{ on }">
										<v-text-field outlined dense
											v-model="formulaire_ajout_element.annee_mois_formatee"
											label="Mois / année"
											hide-details
											readonly
											prepend-inner-icon="mdi-calendar"
											@blur="formulaire_ajout_element.annee_mois_formatee = formatMoisAnnee(formulaire_ajout_element.mois) "
											v-on="on"></v-text-field>
									</template>
									<v-date-picker type="month" v-model="formulaire_ajout_element.mois" @input="menu_mois_annee = false"></v-date-picker>
								</v-menu>
							</v-col>
						</v-row>
					</v-expand-transition>
				</v-card-text>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="blue-grey darken-1" text @click="annuleAjout">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="ajoutAstreinteManoeuvre" :loading="chargement_ajout" :disabled="estFormulaireAjoutInvalid">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
	</v-container>
</template>

<script>
	import axios from 'axios'
	import moment from 'moment'
	import { required, requiredIf } from 'vuelidate/lib/validators'
	moment.locale('fr')
    export default {
        created() {
			this.$store.commit('majTitrePage', 'Astreintes et manoeuvres')
        },
        mounted() {
			//this.$store.dispatch('getAstreintesManoeuvres', this.$store.getters.membreCourant.centre.id)
			this.$refs.calendar.checkChange()
		},
		data: vm => ({
			affichageDetail: false,
			affichage_update_astr_man: false,
			chargement_ajout: false,
			chargement_confirmation: false,
			chargement_mois_precedent: false,
			chargement_mois_suivant: false,
			chargement_update: false,
			dialogue_confirmation: {
                titre: '',
                texte: '',
                action: ''
            },
			end: null,
			evenementCourant: null,
			formulaire_ajout_element: {
				type: '',
				id_equipe: null,
				date_debut: null,
				date_debut_formatee: null,
				date_fin: null,
				date_fin_formatee: null,
				heure_debut: null,
				heure_fin: null,
				mois: null,
				annee_mois_formatee: null,
			},
			formulaire_maj_element: {
				id: null,
				id_equipe: null,
				type: '',
				date_debut: null,
				date_debut_formatee: null,
				date_fin: null,
				date_fin_formatee: null,
				heure_debut: null,
				heure_fin: null,
				mois: null,
				annee_mois_formatee: null,
			},
			jours_semaine: [1,2,3,4,5,6,0],
			liste_astreintes: [],
			liste_manoeuvres: [],
			menu_date_debut: false,
			menu_date_debut_ajout: false,
			menu_date_fin: false,
			menu_date_fin_ajout: false,
			menu_heure_debut: false,
			menu_heure_debut_ajout: false,
			menu_heure_fin: false,
			menu_heure_fin_ajout: false,
			menu_mois_annee: false,
			menu_mois_annee_update: false,
			openDialogueAjoutAstreinteManoeuvre: false,
			openDialogueConfirmation: false,
			selectedEvent: {},
      		selectedElement: null,
      		selectedOpen: false,
			start: '2019-01-01'	// date aléatoire initiale
		}),
		computed: {
			affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
			},
			centre_courant() {
				return this.$store.getters.membreCourant.centre
			},
			estFormulaireAjoutInvalid() {
				const date_heure_debut = this.formulaire_ajout_element.date_debut +' '+ this.formulaire_ajout_element.heure_debut
				const date_heure_fin = this.formulaire_ajout_element.date_fin +' '+ this.formulaire_ajout_element.heure_fin
				return this.$v.formulaire_ajout_element.$invalid || moment(date_heure_debut).isAfter(moment(date_heure_fin))
            },
			estFormulaireUpdateInvalid() {
				const date_heure_debut = this.formulaire_maj_element.date_debut +' '+ this.formulaire_maj_element.heure_debut
				const date_heure_fin = this.formulaire_maj_element.date_fin +' '+ this.formulaire_maj_element.heure_fin
				return this.$v.formulaire_maj_element.$invalid || moment(date_heure_debut).isAfter(moment(date_heure_fin))
            },
			evenements() {
				//	chaque événement doit avoir a minima la forme:
				// {
				// 	name: 'Vacation',
				// 	details: 'Going to the beach!',
				// 	start: '2018-12-29',
				// 	end: '2019-01-01',
				// 	color: 'blue',
				// },
				const couleurs_astreintes = ['red', 'blue', 'green', 'orange']
				const couleurs_manoeuvres = ['red lighten-2', 'blue lighten-2', 'green lighten-2', 'orange lighten-2']
				const couleur_manoeuvre_commune = 'brown lighten-2'
				let liste = []	//	contiendra la liste des événements à afficher (astreintes et manoeuvres)

				//	ajout des astreintes
				for (const astreinte of this.liste_astreintes) {
					const indice_couleur = astreinte.equipe.id % 4	//	reste de la division par 4 (4 = nbre de couleurs dispo)
					
					liste.push({
						name: 'Astreinte '+astreinte.equipe.nom,
						start: moment(astreinte.date_debut).format('YYYY-MM-DD'),	// pour ne pas afficher l'heure
						end: moment(astreinte.date_fin).subtract(1, 'days').format('YYYY-MM-DD'),	// pour ne pas que ca déborde sur le lundi 
						color: couleurs_astreintes[indice_couleur],
						id: astreinte.id,
						id_equipe: astreinte.id_equipe,
						debut_reel: astreinte.date_debut,
						fin_reelle: astreinte.date_fin,
						nom_detail: 'Astreinte '+astreinte.equipe.nom,
						description_detail: 'du '+ moment(astreinte.date_debut).format('dddd DD/MM/YYYY')+' à '+moment(astreinte.date_debut).format('H:mm') + '	au '+ moment(astreinte.date_fin).format('dddd DD/MM/YYYY') + ' à ' +moment(astreinte.date_fin).format('H:mm'),
						type: 'astreinte'
					})
				}

				//	ajout des manoeuvres
				for (const manoeuvre of this.liste_manoeuvres) {
					//affichage de "du" ou "d'" selon le mois
					const article = [4,8,10].includes(manoeuvre.mois) ? "d'" : "de "

					// couleur de la manoeuvre
					const couleur = manoeuvre.equipes.length > 1 ? couleur_manoeuvre_commune : couleurs_astreintes[manoeuvre.equipes[0].id % 4]

					let texte_equipe = ''
					let texte_equipe_detail = ''
					if(manoeuvre.equipes.length == 1) {
						texte_equipe = manoeuvre.equipes[0].nom
						texte_equipe_detail = texte_equipe
					}
					else {
						texte_equipe = 'commune'
						for (const [index, equipe] of manoeuvre.equipes.entries()) {
							if(index != manoeuvre.equipes.length -1) {
								texte_equipe_detail += equipe.nom+' / '
							}
							else {
								texte_equipe_detail += equipe.nom
							} 
						}
					}

					let liste_ids_equipe = []
					for (const equipe of manoeuvre.equipes) {
						liste_ids_equipe.push(equipe.id)
					}

					liste.push({
						name: 'Man. '+texte_equipe,
						start: moment(manoeuvre.date_debut).format('YYYY-MM-DD'),
						end: moment(manoeuvre.date_fin).format('YYYY-MM-DD'),
						color: couleur,
						id: manoeuvre.id,
						id_equipe: liste_ids_equipe,
						debut_reel: manoeuvre.date_debut,
						fin_reelle: manoeuvre.date_fin,
						nom_detail: 'Manoeuvre '+texte_equipe_detail,
						description_detail: 'le '+ moment(manoeuvre.date_debut).format('dddd DD/MM/YYYY')+' de '+moment(manoeuvre.date_debut).format('H:mm') + ' à ' +moment(manoeuvre.date_fin).format('H:mm') + ', comptant pour la manoeuvre '+ article + moment(manoeuvre.mois, 'M').format('MMMM')+' '+manoeuvre.annee+'.',
						type: 'manoeuvre',
						annee: manoeuvre.annee,
						mois: manoeuvre.mois,
						equipes: manoeuvre.equipes,
					})
			
				}
				return liste
			},
			liste_equipes() {
				//return this.$store.getters.membreCourant.centre.equipes
				let liste = []
                this.$store.getters.membreCourant.centre.equipes.forEach(equipe => {
					//	on ne garde que les équipes SPV
					if(!equipe.est_spp) {
						const type = equipe.est_spp ? ' (SPP)' : ' (SPV)'
						liste.push({id: 
							equipe.id, 
							nom: equipe.nom + type})
					}
                })
                return liste
			},
			monthFormatter () {
				return this.$refs.calendar.getFormatter({
					timeZone: 'UTC', month: 'long',
				})
			},
			titre_mois() {
				const { start, end } = this
				if (!start || !end) {
					return ''
				}
				const startMonth = this.monthFormatter(start)
				const startYear = start.year
				return `${startMonth} ${startYear}`
			}
  
		},
		methods: {
			acces_autorise(permissions_requises) {
                // retourne true si l'utilisateur à les permissions listées
                
                let permission_trouvee = false

                if(permissions_requises.length > 0 && this.$store.getters.membreCourant) {
                
                    const roles_utilisateur = this.$store.getters.membreCourant.roles
                    const permissions_utilisateur = this.$store.getters.membreCourant.permissions

                    const estSuperAdmin = roles_utilisateur.find(role => role.name == 'Super admin')
                    

                    //	si super admin on autorise tout
                    if (estSuperAdmin) {
                        permission_trouvee = true
                    }
                    else {
                        //	on cherche si la permission demandée se trouve dans un des roles de l'utilisateur
                        roles_utilisateur.forEach(role => {
                            role.permissions.forEach(permission => {
                                    if( permissions_requises.includes(permission.name) ) {
                                        permission_trouvee = true
                                    }
                                })
                        })
                    

                        // si la permission n'a pas déjà été trouvée, on cherche si elle est dans les permissions directes
                        if (!permission_trouvee) {
                            
                            permissions_utilisateur.forEach(permission => {
                                if( permissions_requises.includes(permission.name) ) {
                                    permission_trouvee = true
                                }
                            })
                        }
                    }
                }

                //	on autorise si pas de permission ou si une permission et que la permission est trouvée
                return permissions_requises.length == 0 || (permissions_requises.length > 0 && permission_trouvee)
			},
			ajoutAstreinteManoeuvre() {
				if( !this.$v.formulaire_ajout_element.$invalid) {
					this.chargement_ajout = true
					const date_heure_debut = this.formulaire_ajout_element.date_debut +' '+ this.formulaire_ajout_element.heure_debut
					const date_heure_fin = this.formulaire_ajout_element.date_fin +' '+ this.formulaire_ajout_element.heure_fin

					axios.post('api/astreintes_manoeuvres/add', { 
						type: this.formulaire_ajout_element.type, 
						id_equipe: this.formulaire_ajout_element.id_equipe,
						id_membre: this.$store.getters.membreCourant.id,
						date_debut: date_heure_debut,
						date_fin: date_heure_fin,
						mois: moment(this.formulaire_ajout_element.mois, 'YYYY-MM').format('M'),
						annee: moment(this.formulaire_ajout_element.mois, 'YYYY-MM').format('YYYY')
					})
                    .then((reponse) => {
                        this.snackbar('success',reponse.data.resultat);
                        this.fetchAstreintesManoeuvresCentre()
                    })
                    .catch((error) => {
                        this.snackbar('error', 'Erreur lors de la création');
                    })
                    .then(() => {
						this.chargement_ajout = false
						this.annuleAjout()
                    })
				}
				
			},
			annuleAjout() {
				this.formulaire_ajout_element.type = ''
				this.formulaire_ajout_element.id_equipe = null
				this.formulaire_ajout_element.date_debut = null
				this.formulaire_ajout_element.date_debut_formatee = null
				this.formulaire_ajout_element.date_fin = null
				this.formulaire_ajout_element.date_fin_formatee = null
				this.formulaire_ajout_element.heure_debut = null
				this.formulaire_ajout_element.heure_fin = null
				this.formulaire_ajout_element.mois = null
				this.formulaire_ajout_element.annee_mois_formatee = null
				this.openDialogueAjoutAstreinteManoeuvre = false
			},
			annuleConfirmation() {
                this.dialogue_confirmation.titre = ''
                this.dialogue_confirmation.texte = ''
                this.dialogue_confirmation.action = ''
                this.openDialogueConfirmation = false
            },
			fetchAstreintesManoeuvresCentre() {
				axios.post('api/centre/astreintes_manoeuvres', { id_centre: this.$store.getters.membreCourant.centre.id ,date_debut: this.start.date, date_fin: this.end.date })
                    .then( (reponse) => {
						this.liste_astreintes = reponse.data.astreintes
						this.liste_manoeuvres = reponse.data.manoeuvres
                    })
                    .catch( (error) => {
                        this.snackbar('error', 'Erreur lors du chargement des astreintes et manoeuvres');
                    })
                    .then(() => {
                        this.chargement_mois_suivant = false
						this.chargement_mois_precedent = false
                    })
			},
			formatDate(date) {
				if(!date) return null
				return moment(date).format('DD/MM/YYYY')
			},
			formatMoisAnnee(date) {
				if(!date) return null
				return moment(date).format('MMMM YYYY')
			},
			getEventColor (event) {
				return event.color
			},
			mois_precedent() {
				this.chargement_mois_precedent = true
				this.$refs.calendar.prev()
			},
			mois_suivant() {
				this.chargement_mois_suivant = true
				this.$refs.calendar.next()
			},
			onClickAddAsteinteManoeuvre() {
				this.openDialogueAjoutAstreinteManoeuvre = true
			},
			onClickAnnulUpdate() {
				this.formulaire_maj_element.id = null
				this.formulaire_maj_element.type = ''
				this.formulaire_maj_element.date_debut = null
				this.formulaire_maj_element.date_debut_formatee = null
				this.formulaire_maj_element.date_fin = null
				this.formulaire_maj_element.date_fin_formatee = null
				this.formulaire_maj_element.heure_debut = null
				this.formulaire_maj_element.heure_fin = null
				this.formulaire_maj_element.mois = null
				this.formulaire_maj_element.annee_mois_formatee = null
				this.formulaire_maj_element.id_equipe = null
				this.affichage_update_astr_man = false
			},
			onClickChangeType() {
				this.formulaire_ajout_element.id_equipe = null
				this.formulaire_maj_element.id_equipe = null
			},
			onClickSupprElement() {
				this.dialogue_confirmation.titre = 'Suppression'
				this.dialogue_confirmation.texte = 'Supprimer '+this.selectedEvent.nom_detail +' ?'
				this.dialogue_confirmation.action = 'suppr_element'
				this.openDialogueConfirmation = true
			},
			onClickUpdateElement() {
				//console.log('element: ', this.selectedEvent)

				// pré-remplissage des données
				this.formulaire_maj_element.id = this.selectedEvent.id
				this.formulaire_maj_element.type = this.selectedEvent.type
				this.formulaire_maj_element.id_equipe = this.selectedEvent.id_equipe	
				this.formulaire_maj_element.date_debut = moment(this.selectedEvent.debut_reel).format('YYYY-MM-DD')
				this.formulaire_maj_element.heure_debut = moment(this.selectedEvent.debut_reel).format('HH:mm')
				this.formulaire_maj_element.date_fin = moment(this.selectedEvent.fin_reelle).format('YYYY-MM-DD')
				this.formulaire_maj_element.heure_fin = moment(this.selectedEvent.fin_reelle).format('HH:mm')
				this.formulaire_maj_element.mois = this.selectedEvent.type == 'manoeuvre' ? this.selectedEvent.annee + '-' + this.selectedEvent.mois : null
				this.affichage_update_astr_man = true
			},
			onClickValidUpdate() {
				//  on vérifie que le formulaire est valide
                if( !this.$v.formulaire_maj_element.$invalid) {
					this.chargement_update = true

					const date_heure_debut = this.formulaire_maj_element.date_debut +' '+ this.formulaire_maj_element.heure_debut
					const date_heure_fin = this.formulaire_maj_element.date_fin +' '+ this.formulaire_maj_element.heure_fin

					axios.post('api/astreintes_manoeuvres/update', { 
						type: this.selectedEvent.type, 
						id_element: this.selectedEvent.id, 
						id_membre: this.$store.getters.membreCourant.id,
						date_debut: date_heure_debut,
						date_fin: date_heure_fin,
						id_equipe: this.formulaire_maj_element.id_equipe,
						mois: moment(this.formulaire_maj_element.mois, 'YYYY-MM').format('M'),
						annee: moment(this.formulaire_maj_element.mois, 'YYYY-MM').format('YYYY')
					})
                    .then((reponse) => {
                        this.snackbar('success',reponse.data.resultat);
						this.fetchAstreintesManoeuvresCentre()
						
                    })
                    .catch((error) => {
                        this.snackbar('error', 'Erreur lors de la modification');
                    })
                    .then(() => {
						this.chargement_update = false
						this.selectedOpen = false
                        this.onClickAnnulUpdate()
                    })

				}
			},
			showEvent ({ nativeEvent, event }) {
				this.affichage_update_astr_man = false
				this.affichageDetail = true
				this.onClickAnnulUpdate()	// effacement des données précédentes si existantes
								
				const open = () => {
					this.selectedEvent = event
					this.selectedElement = nativeEvent.target
					setTimeout(() => this.selectedOpen = true, 10)
				}

				if (this.selectedOpen) {
					this.selectedOpen = false
					setTimeout(open, 10)
				} else {
					open()
				}

				nativeEvent.stopPropagation()
			},
			snackbar(type,message){
                this.$store.commit('afficheSnackbar', {type: type, message: message});
			},
			updateRange ({ start, end }) {
				// You could load events from an outside source (like database) now that we have the start and end dates on the calendar
				// on met à jour les date de début et fin de mois
				this.start = start
				this.end = end
				this.fetchAstreintesManoeuvresCentre()
				
			},
			validConfirmation() {
                switch (this.dialogue_confirmation.action) {
                    case 'suppr_element':
                    	this.chargement_confirmation = true
                    	axios.post('api/astreintes_manoeuvres/suppr', { type: this.selectedEvent.type, id_element: this.selectedEvent.id, id_membre: this.$store.getters.membreCourant.id})
                            .then((reponse) => {
                                this.snackbar('success',reponse.data.resultat);
                                this.fetchAstreintesManoeuvresCentre()
                            })
                            .catch((error) => {
                                this.snackbar('error', 'Erreur lors de la suppression');
                            })
                            .then(() => {
                                this.chargement_confirmation = false
                                this.annuleConfirmation()
                    		})
                    	break;
                    // case 'annulation_changement':
                    //     this.chargement_confirmation = true
                    //     axios.post('api/user/manoeuvres_membre/annulation_changement', { id_membre: this.manoeuvres_membre.id, id_message: this.id_message, ids_manoeuvre: this.listes_autres_manoeuvres_id})
                    //         .then((reponse) => {
                    //             this.snackbar('success',reponse.data.resultat);
                    //             this.$store.dispatch('getManoeuvresMembre', this.$store.getters.membreCourant.centre.id)    
                    //         })
                    //         .catch((error) => {
                    //             this.snackbar('error', 'Erreur lors de l\'annulation');
                    //         })
                    //         .then(() => {
                    //             this.chargement_confirmation = false
                    //             this.annuleConfirmation()
                    //         })
                    //     break;
                    case 'changement_date':
                        this.chargement_confirmation = true
                        axios.post('api/user/manoeuvres_membre/changement_date', { id_membre: this.manoeuvres_membre.id, id_manoeuvre_demandee: this.nouvelle_manoeuvre_choisie == "0" ? 0 : this.nouvelle_manoeuvre_choisie.id , id_manoeuvre_equipe: this.manoeuvre_confirmee_id, ids_manoeuvre: this.listes_autres_manoeuvres_id})
                            .then((reponse) => {
                                this.snackbar('success',reponse.data.resultat);
                                this.$store.dispatch('getManoeuvresMembre', this.$store.getters.membreCourant.centre.id)    
                            })
                            .catch((error) => {
                                this.snackbar('error', 'Erreur lors de l\'envoi de la demande');
                            })
                            .then(() => {
                                this.chargement_confirmation = false
                                this.show_autres_dates = false
                                this.annuleConfirmation()
                            })
                        break;

                
                    default:
                        break;
                }
			},

        },
        validations: {
            formulaire_maj_element: {
                date_debut: {
                    required               
                },
                date_fin: {
                    required               
                },
				heure_debut: {
                    required               
                },
				heure_fin: {
                    required               
				},
				mois: {
					required: requiredIf(function (model) {
						return model.type == 'manoeuvre'
					})
				}
			},
			formulaire_ajout_element: {
				id_equipe: {
					required
				},
				date_debut: {
                    required               
                },
                date_fin: {
                    required               
                },
				heure_debut: {
                    required               
                },
				heure_fin: {
                    required               
				},
				type: {
					required
				},
				mois: {
					required: requiredIf(function (model) {
						return model.type == 'manoeuvre'
					})
				}
				
			},
		},
		watch: {
			centre_courant() {
				
				//  si pas autorisé à rester sur cette page on le vire
                if(!this.acces_autorise('acces gestion astreintes manoeuvres')) {
					this.$router.push('/')
				}
				else {
					this.fetchAstreintesManoeuvresCentre()
				}
			},
			formulaire_maj_element: {
				handler(val) {
					this.formulaire_maj_element.date_debut_formatee = this.formatDate(this.formulaire_maj_element.date_debut)
					this.formulaire_maj_element.date_fin_formatee = this.formatDate(this.formulaire_maj_element.date_fin)
					this.formulaire_maj_element.annee_mois_formatee = this.formatMoisAnnee(this.formulaire_maj_element.mois)
				},
				deep: true
				
			},
			formulaire_ajout_element: {
				handler(val) {
					this.formulaire_ajout_element.date_debut_formatee = this.formatDate(this.formulaire_ajout_element.date_debut)
					this.formulaire_ajout_element.date_fin_formatee = this.formatDate(this.formulaire_ajout_element.date_fin)
					this.formulaire_ajout_element.annee_mois_formatee = this.formatMoisAnnee(this.formulaire_ajout_element.mois)
				},
				deep: true
				
			}
		}
  	}
</script>

<style>
#calendrier_astreintes_manoeuvres{
	height: auto;
}

#calendrier_astreintes_manoeuvres .v-calendar-weekly__week {
	min-height: 95px;
}


#calendrier_astreintes_manoeuvres .v-calendar-weekly__head-weekday {
	background-color: #FAFAFA;
	color: #212121;
	padding-bottom: 5px;
	border-right: none;
	
	border-bottom: #e0e0e0 1px solid;
}

#calendrier_astreintes_manoeuvres {
	border-top: none;
	border-left: none;
}

#calendrier_astreintes_manoeuvres .v-calendar-weekly__day{
	border-left: #e0e0e0 1px solid;
}

</style>